import { client } from "@api/client";
import { PAGES } from "@api/queries";
import { FILTER_COLLECTIONS } from "@api/queries/collections";
import { ENTRIES_SLUG, ENTRY, FILTER_ENTRIES } from "@api/queries/entries";
import { DOMAINS } from "@api/queries/sites";
import { useLazyQuery } from "@hooks/useApi";
import { getEntryLabel } from "@mosanic/fields/Source/helpers";
import { convertEntry, useEntry } from "@mosanic/core/Collections/useEntries";
import { PageRender } from "@mosanic/core/Page/Render";
import Head from "next/head";

import {
  findPage,
  getCollectionsPathMap,
  getCustomDomains,
  getDynamicPart,
  getIndexPageId,
  getSitesDomainPathMap,
  getSubdomains
} from "@mosanic/utils/slug";

import { setSiteId } from "@redux/auth/action";


import { isString, slugify, toLowCase } from "@util/Text";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { parseImageSrc } from "@mosanic/utils/Image";
import { useRouter } from "next/router";

const useRetrieveEntry = (filterEntry) => {
    let [handler, [entries, loadingCollection, errorCollection]] = useLazyQuery('entryMany', FILTER_ENTRIES, null) 
    useEffect(() => {
      if(filterEntry?.collectionId && filterEntry?.slug) handler( filterEntry ? {filter: filterEntry} : null)
    },[filterEntry?.collectionId, filterEntry?.slug])
  

    // const entries = await client.query('entryMany', ENTRIES_SLUG, {filter: filterEntry} )
    // const fullEntry = await client.query('entryById', ENTRY, {_id: entries?.[0]?._id} )
    // const  [handlerEntry, [fullEntry]] = useEntry({_id: entries?.[0]?._id})
    // return entries
    return convertEntry(entries?.[0]);
}

export default function Index({ data, entry, page, header, footer}) {
  const {locale} = useRouter()
  header = header ? JSON.parse(header) : null;
  footer = footer ? JSON.parse(footer) : null;

  page = page ? JSON.parse(page) : null;
  data = data ? JSON.parse(data) : null;

  const dispatch = useDispatch()
  useEffect(() => {
    if(data?._id) dispatch(setSiteId({siteId: data?._id}))
  },[data?._id, dispatch])

  // collection = collection ? JSON.parse(collection) : null;
  entry = entry ? JSON.parse(entry) : null;


  let fullEntry = useRetrieveEntry(entry?.retrieve)
  if(fullEntry) entry = {...entry, ...fullEntry}
  const favicon = data?.logo?.favicon?.img?.url || null
  return ( 
    <>
    {favicon && (
        <Head>
          <link rel="icon" href={parseImageSrc(favicon, true)?.thumb} />
        </Head>
    )}


    {/* {JSON.stringify(data)} */}
    {/* <Seo */}

    {/* <pre style={{zIndex: 9999, position: 'fixed'}}>
      {JSON.stringify({locale, seo: page?.seo, title: {...data, nav: undefined, indexes: undefined, logo: undefined}}, null, 2)}
    </pre> */}

    {data?.code?.header && (
      <span dangerouslySetInnerHTML={{ __html: data?.code?.header }} />
    )}
    <PageRender 
      page={page}
      seo={page?.seo}
      locale={locale}
      entry={entry}
      headerPage={header}
      footerPage={footer}
      site={data}
    />
    {data?.code?.footer && (
      <span dangerouslySetInnerHTML={{ __html: data?.code?.footer }} />
    )}
    </>
  )
}

 

export const getStaticPaths = async () => {
  // const sites = await client.query('siteMany', DOMAINS )

  const domains = await client.query({ query: DOMAINS })
  const sites = domains?.data?.siteMany;


  const filter = {catchRoutes: true}
  // const collections = await client.query('collectionMany', FILTER_COLLECTIONS, {filter} )

  const catchCollections = await client.query({ query: FILTER_COLLECTIONS, variables: {filter} })
  const collections = catchCollections?.data?.collectionMany;

  const subdomains = getSubdomains(sites)
  const customDomains = getCustomDomains(sites)

  const paths = [
    ...getSitesDomainPathMap(subdomains, 'subdomain'),
    ...getSitesDomainPathMap(customDomains, 'customDomain'),
    ...getCollectionsPathMap(sites, collections),
  ].flat();

  return { 
    paths,
    fallback: true, // allows ISRg
    
  }
}; 


export const getStaticProps = async ({params}) => {
  // if (!params) throw new Error("No path parameters found");
  // const sites = await client.query('siteMany', DOMAINS )
  const domains = await client.query({ query: DOMAINS })
  const sites = domains?.data?.siteMany;
  
  //Params
  const { site, slug } = params;

  // Custom or sub domain
  const customDomain = Boolean(site.includes('.'));

  //Get current website by slug
  let website = [
      ...sites.filter(item => (
        customDomain ? 
          item.customDomain == site : 
          item.subdomain == site
      )),
      ...sites.filter(item => item?.domains).filter(item => (
        item?.domains?.filter(d => d.name === customDomain)
      ))
  ][0]; 

  const filter = {
    siteId: website?._id
  }
 

  // const pages = await client.query('pageMany', PAGES, {filter})
  const pagesQuery = await client.query({ query: PAGES, variables: {filter} })
  const pages = pagesQuery?.data?.pageMany;

  let page = slug ? pages?.find(page => toLowCase(page?.seo?.slug) === slug[0]) : null

  //Get Fallback pages
  const noPage = getIndexPageId(website, '404');
  const homePage = getIndexPageId(website, 'home');
  
  if(!page && noPage && slug) page = findPage(pages, noPage);
  if(!page) page = homePage ? findPage(pages, homePage) : pages[0];

  const header = getDynamicPart(website, pages, 'header');
  const footer = getDynamicPart(website, pages, 'footer');

  let filterCollections
  let filterEntry
  let collections
  let collection
  let entries
  let entry

  //Todo format to handle also sub/set paths without dynamic entry handling
  //todo handle index pages?
  if(slug?.length >= 1) {
    filterCollections = {OR: [
        {catchRoutes: true, siteId: website?._id, baseRoute: slug[0]},
        {catchRoutes: true, siteId: website?._id, editRoute: slug[0]}
    ]}
    // collections = await client.query('collectionMany', FILTER_COLLECTIONS, {filter: filterCollections} )
    const collectionsQuery = await client.query({ query: FILTER_COLLECTIONS, variables: {filter: filterCollections} })
    const collections = collectionsQuery?.data?.collectionMany;

    collection = collections?.[0];
    entry = (Array.isArray(collection?.entries) && collection?.entries?.length >= 1) ? collection?.entries?.find(entry => slugify(getEntryLabel(entry, collection.schema)) === slug[1]) : null;

    //Let the retreiver handle it on client side
    if((isString(collection?.entries?.[0]) || !collection?.entries?.[0]) && collection?._id){
      filterEntry = {collectionId: collection._id, slug: slug[1]}
      entry = {retrieve: filterEntry}
    
    //If entries are found
    } else if(collection?.entries?.length >= 1) {
      entry = collection?.entries?.find(entry => slugify(getEntryLabel(entry, collection.schema)) === slug[1]);
      if(!entry) entry = collection?.entries?.[0]

    }
   
  }
  const analytics = website?.analytics;

  return {
    props: {
      analytics: {
        gaMeasurementId: analytics?.gaMeasurementId ? analytics.gaMeasurementId : "G-ZQS1M1QEYL",
        analyticsId:  analytics?.analyticsId,
      },
      data: website ? JSON.stringify(website) : null,
      // slug: slug ? JSON.stringify(entry) : null,
      // collection: collection ? JSON.stringify(collection) : null,
      entry: entry ? JSON.stringify(entry) : null,
      // site,
      // stringifiedPage: 'test data'
      page: page ? JSON.stringify(page) : null,
      header: header ? JSON.stringify(header) : null,
      footer: footer ? JSON.stringify(footer) : null,
    },
    revalidate: 120, //1800 30min
  };

};
  